.list li {
  margin: 0.75rem 0;
}

.info {
  color: rgb(0, 49, 0);
  background-color: #F3F3F7;
  padding: 1rem;
  border-radius: 4px;
}

.accordion {
  border-top: 1px solid #ccc;
  margin-top: 1rem;
}

.item {
  margin: 15px 0px;
  border-radius: 8px;
  background-color: #F3F3F7;
}

.itemBtn {
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  color: black;
  background-color: transparent;
  border: none;
}

.itemBtn:hover {
  border-radius: 8px;
  background-color: #e6e6eb;
}

.itemBtnExpanded {
  background-color: #e7e7e7;
}

.itemContent {
  transition: height 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemPanel {
  padding: 1rem;
}

.chevron {
  margin-left: auto;
  transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemBtnExpanded .chevron {
  transform: rotate(180deg);
}